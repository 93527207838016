.footer {
  border-top: 0.1rem solid rgba(var(--color-foreground), 0.08);
}

.footer__content-top {
  padding-bottom: 4rem;
  display: block;
}

@media screen and (max-width: 749px) {
  .footer .grid {
    display: block;
  }

  .footer-block.grid__item {
    padding: 0;
    margin: 0rem 0;
    width: 100%;
  }

  .footer-block.grid__item:first-child {
    margin-top: 0;
  }

  .footer__content-top {
    padding-bottom: 3rem;
    padding-left: calc(2rem / var(--font-body-scale));
    padding-right: calc(2rem / var(--font-body-scale));
  }
}

@media screen and (min-width: 750px) {
  .footer__content-top .grid {
    row-gap: 6rem;
    margin-bottom: 0;
  }
}

.footer__content-bottom {
  border-top: solid 0.1rem rgba(var(--color-border-color));
  padding: 1.4rem;
}

.footer__content-bottom:only-child {
  border-top: 0;
}

@media screen and (max-width: 749px) {
  .footer__content-bottom {
    flex-wrap: wrap;
    padding-top: 0;
    padding-left: 0;
    padding-right: 0;
    row-gap: 1.5rem;
  }

  .footer__content-bottom-wrapper {
    flex-wrap: wrap;
    row-gap: 1.5rem;
    justify-content: center;
  }

  .footer__content-bottom.scroll-trigger.animate--slide-in {
    animation: none;
    opacity: 1;
    transform: inherit;
  }
}

.footer__localization:empty + .footer__column--info {
  align-items: center;
}

@media screen and (max-width: 749px) {
  .footer__localization:empty + .footer__column {
    padding-top: 1.5rem;
  }
}

.footer__column {
  width: 100%;
  align-items: flex-end;
}

.footer__column--info {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-left: 2rem;
  padding-right: 2rem;
}

@media screen and (min-width: 750px) {
  .footer__column--info {
    padding-left: 0;
    padding-right: 0;
    align-items: flex-end;
  }
}

.footer-block:only-child:last-child {
  text-align: center;
  max-width: 76rem;
  margin: 0 auto;
}

@media screen and (min-width: 750px) {
  .footer-block {
    display: block;
    margin-top: 0;
  }
}

.footer-block:empty {
  display: none;
}

.footer-block--newsletter:only-child {
  margin-top: 0;
}

@media screen and (max-width: 749px) {
  .footer-block.footer-block--menu:only-child {
    text-align: left;
  }
}

@media screen and (min-width: 750px) {
  .footer-block--newsletter {
    flex-wrap: nowrap;
    justify-content: center;
  }
}

.footer-block__heading {
  margin-bottom: 0.8rem;
  margin-top: 0;
  text-align: start;
}
.footer-block--menu .footer-block__heading {
  margin-bottom: 2.4rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
}
.footer__list-social:empty,
.footer-block--newsletter:empty {
  display: none;
}

.footer__follow-on-shop {
  display: flex;
  text-align: center;
}

.footer__list-social.list-social:only-child {
  justify-content: center;
}

@media screen and (min-width: 750px) {
  /* Pushes other components to the right of the flexbox */
  .footer-block__newsletter:not(:only-child) {
    text-align: left;
    margin-right: auto;
  }

  .footer-block__newsletter:not(:only-child) .footer__newsletter {
    justify-content: flex-start;
    margin: 0;
  }

  .footer-block__newsletter:not(:only-child)
    .newsletter-form__message--success {
    left: auto;
  }

  .footer__follow-on-shop {
    margin-bottom: 0.4rem;
  }
  /* Follow on shop is the first button but it has siblings*/
  .footer__follow-on-shop:first-child:not(:last-child) {
    justify-content: flex-start;
    margin-right: auto;
    text-align: left;
  }

  /*
    All three components are present, email, Follow on Shop, and social icons.
    Moves the FoS button next to the social icons so they appear grouped together
  */
  .footer__follow-on-shop:not(:first-child):not(:last-child) {
    justify-content: flex-end;
    text-align: right;
  }
}

@media screen and (max-width: 749px) {
  /*
    On a small screen we want all the items to be centered
    because they will be stacked.
   */
  .footer-block--newsletter {
    display: flex;
    flex-direction: column;
    flex: 1 1 100%;
    align-items: center;
    gap: 0 3rem;
  }

  .footer__list-social.list-social,
  .footer__follow-on-shop,
  .footer-block__newsletter {
    display: flex;
    justify-content: center;
  }

  .footer-block__newsletter {
    flex-direction: column;
    width:100%;
  }
}

@media screen and (min-width: 750px) {
  .footer-block__newsletter + .footer__list-social {
    margin-top: 0;
  }
}

.footer__localization {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-content: center;
  flex-wrap: wrap;
  padding: 1rem 1rem 0;
}

.footer__localization:empty {
  display: none;
}

.footer__localization h2 {
  margin: 1rem 1rem 0.5rem;
  color: rgba(var(--color-foreground), 0.75);
}

@media screen and (min-width: 750px) {
  .footer__localization {
    padding: 0.4rem 0;
    justify-content: flex-start;
  }

  .footer__localization h2 {
    margin: 1rem 0 0;
  }
}

@media screen and (min-width: 750px) {
  .footer__payment {
    margin-top: 1.5rem;
  }
}

.footer__content-bottom-wrapper--center {
  justify-content: center;
}

.footer__copyright {
  text-align: center;
  margin-top: 0;
}

@media screen and (min-width: 750px) {
  .footer__content-bottom-wrapper:not(.footer__content-bottom-wrapper--center)
    .footer__copyright {
    text-align: right;
  }
}

@keyframes appear-down {
  0% {
    opacity: 0;
    margin-top: -1rem;
  }
  100% {
    opacity: 1;
    margin-top: 0;
  }
}

.footer-block__details-content {
  margin-bottom: 4rem;
}

@media screen and (min-width: 750px) {
  .footer-block__details-content {
    margin-bottom: 0;
  }

  .footer-block__details-content > p,
  .footer-block__details-content > li {
    padding: 0;
  }

  .footer-block:only-child li {
    display: inline;
  }
}

.footer-block__details-content .list-menu__item--link,
.copyright__content a {
  color: rgba(var(--color-foreground));
}

.footer-block__details-content .list-menu__item--active {
  transition: text-decoration-thickness var(--duration-short) ease;
  color: rgb(var(--color-foreground));
}

@media screen and (min-width: 750px) {
  .footer-block__details-content .list-menu__item--link:hover,
  .copyright__content a:hover {
    color: rgb(var(--color-foreground));
    text-decoration: underline;
    text-underline-offset: 0.3rem;
  }

  .footer-block__details-content .list-menu__item--active:hover {
    text-decoration-thickness: 0.2rem;
  }
}

@media screen and (max-width: 989px) {
  .footer-block__details-content .list-menu__item--link {
    padding-top: 1rem;
    padding-bottom: 1rem;
  }
}
.footer-block__details-content .list-menu__item--link {
  font-size: var(--body);
  line-height: var(--body-line);
}
.footer-block__details-content .list-menu__item--link {
  padding-bottom: 0.8rem;
  padding-top: 0.8rem;
}
@media screen and (min-width: 750px) {
  .footer-block__details-content .list-menu__item--link {
    display: inline-block;
  }

  .footer-block__details-content > :first-child .list-menu__item--link {
    padding-top: 0;
  }
}

.footer-block-image {
  display: flex;
}

.footer-block-image.left {
  justify-content: flex-start;
}

.footer-block-image.center {
  justify-content: center;
}

.footer-block-image.right {
  justify-content: flex-end;
}

@media screen and (max-width: 749px) {
  .footer-block-image,
  .footer-block-image.left,
  .footer-block-image.center,
  .footer-block-image.right {
    justify-content: center;
  }
}

.footer-block__image-wrapper {
  margin-bottom: 2rem;
  overflow: hidden !important;
}

.footer-block__image-wrapper img {
  display: block;
  height: auto;
  max-width: 100%;
}

.footer-block__brand-info {
  text-align: left;
}

.footer-block:only-child .footer-block__brand-info {
  text-align: center;
}

.footer-block:only-child
  > .footer-block__brand-info
  > .footer-block__image-wrapper {
  margin-left: auto;
  margin-right: auto;
}

.footer-block-image > img,
.footer-block__brand-info > img {
  height: auto;
}

.footer-block:only-child
  .footer-block__brand-info
  .footer__list-social.list-social {
  justify-content: center;
}

.footer-block__brand-info .footer__list-social.list-social {
  justify-content: flex-start;
  margin-left: -1.3rem;
  margin-right: -1.3rem;
}

.footer-block__details-content .placeholder-svg {
  max-width: 20rem;
}

.copyright__content {
  gap: 3px;
  color: rgba(var(--color-foreground-subtext));
  flex-wrap: wrap;
  justify-content: center;
}

.copyright__content a {
  color: currentColor;
  text-decoration: none;
}

.policies {
  display: inline;
}

.policies li {
  display: inline-flex;
  justify-content: center;
  align-items: center;
}

.policies li::before {
  content: "\00B7";
  padding: 0 0.8rem;
}

.policies li a {
  padding: 0.6rem 0;
  display: block;
}

@media screen and (min-width: 750px) {
  .policies li a {
    padding: 0;
  }
}
@keyframes animateLocalization {
  0% {
    opacity: 0;
    transform: translateY(0);
  }

  100% {
    opacity: 1;
    transform: translateY(-1rem);
  }
}

/* check for flexbox gap in older Safari versions */
@supports not (inset: 10px) {
  @media screen and (max-width: 749px) {
    .footer .grid {
      margin-left: 0;
    }
  }

  @media screen and (min-width: 750px) {
    .footer__content-top .grid {
      margin-left: -3rem;
    }

    .footer__content-top .grid__item {
      padding-left: 3rem;
    }
  }
}

/* ==============new-css============== */

.block_footer__content-top-inner .block_footer-menu-blocks {
  width: calc(63.09% - 50px);
}
/* .footer-container {
  max-width: 1560px;
} */
.footer-block--newsletter {
  width: calc(36.91% - 50px);
}
.block_footer__content-top-inner {
  display: flex;
  gap: 30px 100px;
  flex-wrap: wrap;
}
.footer {
  border-top: 1px solid rgb(var(--color-border-color));
}
.block_news-field {
  background: var(--tertiary-color);
}
.footer .newsletter-form {
  max-width: 47rem;
  margin: 0;
}
.footer-block__subheading {
  padding-bottom: 32px;
}
.footer-block__details-content > li:last-child a {
  padding-bottom: 0;
}
.block_footer-sell-text a,
.block_foote-made-text a {
  color: rgba(var(--color-foreground-subtext));
}
.footer__content-bottom-wrapper-inner {
  justify-content: space-between;
  gap: 15px;
}
.block_footer-sell-text a {
  text-decoration: underline;
  text-underline-offset: 3px;
}
.block_footer-justify {
  justify-content: center;
}
.block_footer-link-minus {
  display: none;
}
.block_footer-logo{
  padding-top:20px;
 
}
.block_footer-logo img {
  object-fit: contain;
  max-width: fit-content;
}
.block_footer-rewards-number-icon a img {
  width: 24px;
  height: 24px;
  object-fit: contain;
}
.block_footer_logo_flex {
  display: flex;
  align-items: center;
  column-gap: 30px;
  flex-wrap: wrap;
  justify-content: center;
  width: 100%;
}
.block_footer-logo {
  width: 40%;
}
@media (max-width: 1366px) and (min-width: 1025px) {
  .block_footer__content-top-inner {
    gap: 30px;
  }
  .footer-block--newsletter {
    width: calc(36.91% - 15px);
  }
  .block_footer__content-top-inner .block_footer-menu-blocks {
    width: calc(63.09% - 15px);
  }
  .footer-block__details-content .list-menu__item--link,
  .footer-block__subheading {
    font-size: var(--small_body);
    line-height: var(--small_body-line);
  }
  .footer-block__heading {
    font-size: var(--small_body);
  }
}

@media (max-width: 1024px) {
  .footer-block--newsletter,
  .block_footer__content-top-inner .block_footer-menu-blocks {
    width: 100%;
  }
  .footer__content-bottom-wrapper-inner {
    flex-wrap: wrap;
    flex-direction: column;
    padding: 24px 0 0px;
  }
  .footer__content-bottom{
    padding-bottom:3rem;
  }
 .footer__content-bottom{
   border-top:0;
 }
}
@media (max-width: 749px) {
  .footer-block__details-content {
    max-height: 0;
    transition:var(--animation);
    height: 0;
    opacity: 0;
    overflow: hidden;
    margin: 0;

  }
  .footer-block--menu.active .footer-block__details-content {
    height: 100%;
    opacity: 1;
    max-height: 500px;
    overflow-y: hidden;
    padding-bottom:20px;
  }
  .footer-block--menu.active .block_footer-link-plus {
    display: none;
  }
  .footer-block--menu.active .block_footer-link-minus {
    display: block;
  }
  .footer-block--menu .footer-block__heading {
    margin: 0;
    padding: 2.4rem 0;
  }
  .footer-block.grid__item:not(:last-child){
    border-bottom:1px solid rgba(var(--color-border-color));;
  }
  .footer{
    border:none;
  }
  .footer-block__heading.footer-block__newsletter{
    font-size:var(--h3)
  }
.footer-block__subheading{
  padding-bottom:20px;
}
  .block_footer__content-top-inner{
    gap:25px;
  }
}
@media (max-width:575px){
  .block_footer-logo {
    width: 100%;
    text-align: center;
}
}